import React, { Component } from "react";
import "./Skills.css";
import { Fade } from "react-reveal";
import ProjectCard from "../../components/ProjectCard/ProjectCard";
import ProductCardHome from "../../components/ProductCardHome/ProductCardHome";
import PartnerCard from "../../components/PartnerCard/PartnerCard";

import { products } from "../../portfolio";
class OutWork extends Component {
  render() {
    const theme = this.props.theme;

    const p1 = {
      name: "Industry Solutions",
      bg: theme.cardBg,
      bgImg: "../../assests/images/oil.jpg",
      class: "repo-card-div",
    };
    const p2 = {
      name: "Power Solutions",
      bg: theme.cardBg2,
      bgImg: "../../assests/images/oil.jpg",
      class: "repo2-card-div",
    };
    const p3 = {
      name: "Mining Solutions",
      bg: theme.cardBg3,
      bgImg: "../../assests/images/oil.jpg",
      class: "repo3-card-div",
    };
    const p4 = {
      name: "Marine Solutions",
      bg: theme.cardBg,
      bgImg: "../../assests/images/oil.jpg",
      class: "repo4-card-div",
    };

    const partner = {
      name: "Partner",
      description: "A Partner",
      bg: theme.ptBg,
    };

    return (
      <div>
        <div className="skills-main-div">
          <div className="skills-text-div">
            {/* <Fade duration={1000}>
              <h1 className="skills-heading" style={{color: theme.text }}>{"Our Services"}</h1>
            </Fade> */}
            <div className="flex-container">
              <ProjectCard repo={p1} theme={theme} />
              <ProjectCard repo={p2} theme={theme} />
              <ProjectCard repo={p3} theme={theme} />
              <ProjectCard repo={p4} theme={theme} />
            </div>
            <Fade duration={1000}>
              <h1 className="skills-heading" style={{ color: theme.text }}>
                {"Our Partner"}
              </h1>
            </Fade>
            <div className="flex-container">
              <PartnerCard repo={partner} theme={theme} />
            </div>
            <Fade duration={1000}>
              <h1 className="skills-heading" style={{ color: theme.text }}>
                {"Our Products"}
              </h1>
            </Fade>
            <div></div>
            <div className="resume-btn-div-work">
              <div className="flex-container">
                <ProductCardHome
                  repo={products.filter_inserts[0]}
                  theme={theme}
                />
                <ProductCardHome
                  repo={products.fine_filters[0]}
                  theme={theme}
                />
                <ProductCardHome
                  repo={products.filter_seperators[0]}
                  theme={theme}
                />
                <ProductCardHome
                  repo={products.varnish_removal_insert[0]}
                  theme={theme}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default OutWork;
