import React, { Component } from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import SocialMedia from "../../components/socialMedia/SocialMedia";
import Button from "../../components/button/Button";
import { Fade } from "react-reveal";
import "./ContactComponent.css";
import { contactPageData } from "../../portfolio.js";
import ReCAPTCHA from "react-google-recaptcha";
import Alert from "@material-ui/lab/Alert";

const ContactData = contactPageData.contactSection;
const validEmailRegex = RegExp(
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
);
const validateForm = (errors) => {
  let valid = true;
  Object.values(errors).forEach((val) => val.length > 0 && (valid = false));
  return valid;
};

class Contact extends Component {
  constructor(props) {
    super(props);
    this.submitForm = this.submitForm.bind(this);

    this.state = {
      status: "",
      enable: "false",
      name: "",
      subject: "",
      email: "",
      message: "",
      phone: "",
      captcha: "",
      errors: {
        name: "",
        subject: "",
        email: "",
        message: "",
        phone: "",
        captcha: "",
      },
    };
  }

  submitForm(ev) {
    ev.preventDefault();
    if (validateForm(this.state.errors)) {
      const form = ev.target;
      const data = new FormData(form);
      const xhr = new XMLHttpRequest();
      xhr.open(form.method, form.action);
      xhr.setRequestHeader("Accept", "application/json");
      xhr.onreadystatechange = () => {
        if (xhr.readyState !== XMLHttpRequest.DONE) return;
        if (xhr.status === 200) {
          form.reset();
          this.setState({ status: "SUCCESS", enable: false });
        } else {
          this.setState({ status: "ERROR", enable: false });
        }
      };
      xhr.send(data);
    } else {
      console.log("Invalid Form");
    }
  }

  onChange(value) {
    let errors = this.state.errors;
    this.setState({ ...errors, captcha: value });
  }

  handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let errors = this.state.errors;

    switch (name) {
      case "name":
        errors.name =
          value.length < 4
            ? "Full Name must be at least 4 characters long!"
            : "";
        break;
      case "email":
        errors.email = validEmailRegex.test(value) ? "" : "Email is not valid!";
        break;
      case "subject":
        errors.subject = value.length === 0 ? "Subject cannot be empty!" : "";
        break;
      case "message":
        errors.message = value.length === 0 ? "Message cannot be empty!" : "";
        break;
      case "captcha":
        errors.captcha =
          value.length === 0 ? "Please complete the captcha!" : "";
        break;
      default:
        break;
    }

    this.setState({ errors, [name]: value });
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const theme = this.props.theme;
    const { errors } = this.state;

    return (
      <div className="contact-main">
        <Header theme={theme} />
        <div className="basic-contact">
          <Fade bottom duration={1000} distance="40px">
            <div className="contact-heading-div">
              <div className="contact-heading-text-div">
                <h1
                  className="contact-heading-text"
                  style={{ color: theme.text }}
                >
                  {ContactData["title"]}
                </h1>
                <p
                  className="contact-header-detail-text subTitle"
                  style={{ color: theme.secondaryText }}
                >
                  {"Phone: " + ContactData["phone"]}
                </p>
                <p
                  className="contact-header-detail-text subTitle"
                  style={{ color: theme.secondaryText }}
                >
                  {"Address: " + ContactData["address"]}
                </p>
                <p
                  className="contact-header-detail-text subTitle"
                  style={{ color: theme.secondaryText }}
                >
                  {"Email Address: " + ContactData["email"]}
                </p>
                <p
                  className="contact-header-detail-text subTitle"
                  style={{ color: theme.secondaryText }}
                >
                  {"Business Hours: " + ContactData["bhours"]}
                </p>
                <SocialMedia />
                <div className="resume-btn-div">
                  <Button
                    text={"Find Us on Maps"}
                    className="project-button"
                    href=""
                    newTab={true}
                  />
                </div>
              </div>
              <div className="contact-heading-form-div">
                <form
                  onSubmit={this.submitForm}
                  action="https://formspree.io/mknpnwkw"
                  method="POST"
                  id="form1"
                  noValidate
                >
                  <div className="resume-btn-div">
                    <h1
                      className="contact-header-form-head-text subTitle"
                      style={{ color: "#1F1738" }}
                    >
                      Queries? Here.
                    </h1>
                  </div>
                  <h1
                    className="contact-header-form-detail-text subTitle"
                    style={{ color: theme.text }}
                  >
                    Name :
                  </h1>
                  <input
                    type="text"
                    name="name"
                    onChange={this.handleChange}
                    noValidate
                  />
                  {errors.name.length > 0 && (
                    <p className="errorClass">{errors.name}</p>
                  )}
                  <h1
                    className="contact-header-form-detail-text subTitle"
                    style={{ color: theme.text }}
                  >
                    Subject :
                  </h1>
                  <input
                    type="text"
                    name="subject"
                    onChange={this.handleChange}
                    noValidate
                  />
                  {errors.subject.length > 0 && (
                    <p className="errorClass">{errors.subject}</p>
                  )}
                  <h1
                    className="contact-header-form-detail-text subTitle"
                    style={{ color: theme.text }}
                  >
                    Email :
                  </h1>
                  <input
                    type="text"
                    name="email"
                    onChange={this.handleChange}
                    noValidate
                  />
                  {errors.email.length > 0 && (
                    <p className="errorClass">{errors.email}</p>
                  )}
                  <h1
                    className="contact-header-form-detail-text subTitle"
                    style={{ color: theme.text }}
                  >
                    Message :
                  </h1>
                  <textarea
                    className="contact-header-detail-text-message"
                    type="text"
                    name="message"
                    onChange={this.handleChange}
                  />
                  {errors.message.length > 0 && (
                    <p className="errorClass">{errors.message}</p>
                  )}
                  <h1
                    className="contact-header-form-detail-text subTitle"
                    style={{ color: theme.text }}
                  >
                    Phone Number (Optional) :
                  </h1>
                  <input type="text" name="phone" noValidate />
                  <div className="resume-btn-div">
                    <ReCAPTCHA
                      name="captcha"
                      sitekey="6Ld40tEZAAAAACjhy02bcCXaRvbz5BmZeFVwD6Hd"
                      onChange={this.onChange}
                    />
                  </div>
                  <div className="resume-btn-div">
                    {this.status === "SUCCESS" ? (
                      <Alert severity="success">
                        This is a success alert — check it out!
                      </Alert>
                    ) : (
                      <button className="main-button">Submit</button>
                    )}
                    {this.status === "ERROR" && (
                      <Alert severity="error">
                        This is an error alert — check it out!
                      </Alert>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </Fade>
        </div>
        <Footer theme={this.props.theme} onToggle={this.props.onToggle} />
      </div>
    );
  }
}

export default Contact;
