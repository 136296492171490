import React, { Component } from "react";
import "./ProductCard.css";
import { Fade } from "react-reveal";

function openRepoinNewTab(url) {
  window.focus();
}

const onMouseEnter = (event, color) => {
  const el = event.target;
  el.style.color = color;
};

const onMouseOut = (event) => {
  const el = event.target;
  el.style.color = "#25AEF3";
};
class ProductCard extends Component {
  render() {
    const repo = this.props.repo;
    const theme = this.props.theme;

    return (
      <div>
        <Fade bottom duration={2000} distance="40px">
          <div
            className="product-card-div"
            key={repo.id}
            onClick={() => openRepoinNewTab(repo.url)}
            style={{ backgroundColor: repo.bg }}
          >
            <div className="product-image-div">
              <img className="img-card" src={repo.img} alt=""></img>
            </div>
            <p className="product-name" style={{ color: theme.text }}>
              {repo.name}
            </p>
            <p className="product-description" style={{ color: theme.text }}>
              {repo.description}
            </p>
            <p className="product-details" style={{ color: theme.text }}>
              <p
                style={{
                  color: "#25AEF3",
                  borderRadius: 5,
                  textDecoration: "none",
                }}
                onClick={() => openRepoinNewTab(repo.url)}
                onMouseEnter={(event) => onMouseEnter(event, "#8AC6F3")}
                onMouseOut={(event) => onMouseOut(event)}
              >
                Product Details
              </p>
            </p>
          </div>
        </Fade>
      </div>
    );
  }
}

export default ProductCard;
