// theme.js
export const blueTheme = {
	body: "#EDF9FE",
	text: "#001C55",
	highlight: "#A6E1FA",
	dark: "#00072D",
	secondaryText: "#7F8DAA",
	imageHighlight: "#0E6BA8",
	compImgHighlight: "#E6E6E6",
	jacketColor: "#ECB02B",
	headerColor: "#0E6BA877",
};

export const vjTheme = {
	body: "#FFFFFF",
	text: "#1F1738",
	highlight: "#A94C4C",
	dark: "#00072D",
	secondaryText: "#7F8DAA",
	imageHighlight: "#0E6BA8",
	compImgHighlight: "#E6E6E6",
	jacketColor: "#ECB02B",
	headerColor: "#0E6BA877",
	cardBg: "#E8F0F7",
	cardBg2: "#FAE5F2",
	cardBg3: "#F4F7D6",
	darkShade: "#A94C4C",
	ptBg: "#E8DEFF"
};

export const chosenTheme = vjTheme;