import React from "react";
import "./Footer.css";
import Footer2 from "rc-footer";
import "rc-footer/assets/index.css"; // import 'rc-footer/asssets/index.less';
/* eslint-disable jsx-a11y/accessible-emoji */
import Button from "../../components/button/Button";

// const phone = ContactData["phone"];
// const email = ContactData["email"];
// console.log(address);

export default function Footer(props) {
  // const { height, width } = useWindowDimensions();
  // const w = width <= "460" ? "300px" : "450px";
  // const p = width <= "460" ? "30%" : "0px";

  return (
    <div className="footer-div">
      <Footer2
        backgroundColor="#F5F5F5"
        theme="light"
        style={{ fontSize: 18 }}
        columns={[
          {
            title: "Quick Links",
            openExternal: false,
            items: [
              {
                title: "Home",
                url: "/#/home",
              },
              {
                title: "Products",
                url: "/#/products",
              },
              {
                title: "About Us",
                url: "/#/about",
              },
              {
                title: "Contact Us",
                url: "/#/contact",
              },
            ],
          },
          // {
          //   title: "Contact Us",
          //   openExternal: false,
          //   items: [
          //     {
          //       title: "Address",
          //       description: address,
          //       openExternal: false,
          //       style: { width: w, paddingLeft: p },
          //     },
          //     {
          //       title: "Phone",
          //       description: phone,
          //       openExternal: false,
          //     },
          //     {
          //       title: "Email",
          //       description: email,
          //       openExternal: false,
          //     },
          //   ],
          // },
          // {
          //   title: "Social Links",
          //   openExternal: false,
          //   items: [
          //     // {
          //     //   icon: <img src={require("../../assests/images/youtube.svg")} />,
          //     //   title: "Youtube",
          //     //   openExternal: true,
          //     //   url: "/home",
          //     // },
          //     {
          //       icon: (
          //         <img src={require("../../assests/images/linkedin.svg")} />
          //       ),
          //       title: "LinkedIn",
          //       openExternal: true,
          //       url: "/home",
          //     },
          //   ],
          // },
          {
            title: "Queries?",
            openExternal: false,
            items: [
              {
                icon: (
                  <Button
                    text={"Contact"}
                    className="project-button"
                    href="#/contact"
                    newTab={true}
                  />
                ),
                url: "/contact",
                style: {
                  paddingLeft: "50%",
                  paddingRight: "50%",
                  marginTop: "-50px",
                },
              },
            ],
          },
        ]}
        bottom="Copyright © VJ TechServe Pvt. Ltd. 2020"
      />
    </div>
  );
}
