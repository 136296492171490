import React from "react";
import "./Greeting.css";

import { Paper } from "@material-ui/core";

// function Item(props) {
//   return (
//     <Paper elevation={0} style={{ backgroundColor: "#6B5ECD" }}>
//       <div className="show-image">
//         <div className="repo-btn-div">
//           <img
//             src={require("../../assests/images/oilshow-min.jpg")}
//             className="center"
//             alt="1"
//           />
//           <p className="show-text">
//             VJ TechServe
//             <br />
//             Every solution has an address.
//           </p>
//         </div>
//       </div>
//     </Paper>
//   );
// }

export default function Greeting(props) {
  // const theme = props.theme;
  // var items = [
  //   {
  //     img: "",
  //   },
  //   {
  //     img: "",
  //   },
  //   {
  //     img: "",
  //   },
  //   {
  //     img: "",
  //   },
  // ];

  return (
    // <Fade bottom duration={2000} distance="40px">
    <div className="repo-btn-div">
      <Paper elevation={0} style={{ backgroundColor: "#6B5ECD" }}>
        <div className="show-image">
          <div className="repo-btn-div">
            <img
              src={require("../../assests/images/oilshow-min2.jpg")}
              className="center"
              alt="1"
            />
            <p className="show-text">
              VJ TechServe
              <br />
              <p className="show-text2">Every solution has an address.</p>
            </p>
          </div>
        </div>
      </Paper>
    </div>
    // </Fade>
  );
}
