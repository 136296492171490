import React from "react";
import "./PartnerCard.css";
import { Fade } from "react-reveal";

export default function PartnerCard({ repo, theme }) {
  // function openRepoinNewTab(url) {
  //   var win = window.open(url, "_blank");
  //   win.focus();
  // }

  return (
    <div>
      <Fade bottom duration={2000} distance="40px">
        <div
          className="partner-card-div"
          key={repo.id}
          style={{ backgroundColor: repo.bg }}
        >
          <div className="logo-name">
            <img
              style={{ height: "100%", width: "100%", objectFit: "contain" }}
              className="center2"
              alt="1"
              src={require("../../assests/images/cjc-min.png")}
            />
          </div>
          <div className="partner-info">
            <p className="partner-name">
              VJ Techserve Pvt Ltd is an Authorized Distributor of CJC™
              filtration products for Western & Northern India. C.C.JENSEN A/S
              designs and manufactures CJC™ Offline Oil Filtration Systems for
              removal of particles, oil degradation products and water from oil
              and other fluids.
            </p>
            <a target="_blank" href="https://cjc.dk" className="partner-link">
              www.cjc.dk
            </a>
          </div>
          {/* <div className="partner-name-div">
						<p className="partner-name" style={{ color: theme.text }}>
							{repo.name}
						</p>
					</div>
					<p className="partner-description" style={{ color: theme.text }}>
						{repo.description}
					</p> */}
        </div>
      </Fade>
    </div>
  );
}
