import React, { Component } from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import { Fade } from "react-reveal";
import "./AboutComponent.css";

class About extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    const theme = this.props.theme;
    return (
      <div className="about-main">
        <Header theme={theme} />
        <div className="basic-contact">
          <Fade bottom duration={1000} distance="40px">
            <div className="about-heading-div">
              <div className="about-heading-text-div">
                <h1
                  className="about-heading-text-top"
                  style={{ color: theme.text }}
                >
                  {"About Us"}
                </h1>
                <p
                  className="about-header-detail-text subTitle"
                  style={{ color: theme.secondaryText }}
                >
                  {
                    "VJ TechServe, as the name explains, is a company based on the concept of providing Technical Services. Our experience in various fields i.e. financing, manufacturing, procurement, sales, after sales services, logistics and warehousing for various applications has taught us one thing: “Customer is always right and deserves an optimum solution at any given point”."
                  }
                </p>
                {/* <SocialMedia /> */}
                {/* <div className="resume-btn-div">
										<Button
											text="See My Resume"
											newTab={true}
											href={greeting.resumeLink}
											theme={theme}
										/>
									</div> */}
              </div>
            </div>
            <div className="about-heading-text-div">
              <div className="about-heading-text-div">
                <h1
                  className="about-heading-text"
                  style={{ color: theme.text }}
                >
                  {"Vision"}
                </h1>
                <p
                  className="about-header-detail-text subTitle"
                  style={{ color: theme.secondaryText }}
                >
                  {
                    "Leading oil filtration solution and service provider of India."
                  }
                </p>
                {/* <SocialMedia /> */}
                {/* <div className="resume-btn-div">
										<Button
											text="See My Resume"
											newTab={true}
											href={greeting.resumeLink}
											theme={theme}
										/>
									</div> */}
              </div>
              <h1 className="about-heading-text" style={{ color: theme.text }}>
                {"Mission"}
              </h1>
              <p
                className="about-header-detail-text subTitle"
                style={{ color: theme.secondaryText }}
              >
                {
                  "Help customers improve their cost economics, prevent machinery damage, and greatly reduce carbon footprint with help of 3R principle."
                }
              </p>
              <p
                className="about-header-detail-bullet1 subTitle"
                style={{ color: theme.secondaryText }}
              >
                {"oil contamination, waste oil, expense on new oil"}
              </p>
              <p
                className="about-header-detail-bullet2 subTitle"
                style={{ color: theme.secondaryText }}
              >
                {"same oil repeatedly"}
              </p>
              <p
                className="about-header-detail-bullet3 subTitle"
                style={{ color: theme.secondaryText }}
              >
                {
                  "used oil, keep it super clean & dry, retain its properties, extend its shelf life"
                }
              </p>
              {/* <SocialMedia /> */}
              {/* <div className="resume-btn-div">
										<Button
											text="See My Resume"
											newTab={true}
											href={greeting.resumeLink}
											theme={theme}
										/>
									</div> */}
            </div>
          </Fade>
        </div>
        <Footer theme={this.props.theme} onToggle={this.props.onToggle} />
      </div>
    );
  }
}

export default About;
