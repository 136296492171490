import React from "react";
import "./Button.css";

const onMouseEnter = (event, color, bgColor) => {
	const el = event.target;
	el.style.color = color;
	el.style.backgroundColor = bgColor;
}

const onMouseOut = (event, color, bgColor) => {
	const el = event.target;
	el.style.color = color;
	el.style.backgroundColor = bgColor;
}

export default function Button({ text, className, href, newTab, theme }) {
  return (
		<div className={className}>
			<a
				class="main-button"
				href={href}
				target={newTab && "_blank"}
				style={{
					color: "#E6F7FF",
					backgroundColor: "#A94C4C",
          			border: `solid 1px #C17171`
				}}
				onMouseEnter={(event) => onMouseEnter(event, "#FFFFFF", "#C17171")}
				onMouseOut={(event) => onMouseOut(event, "#E6F7FF", "#A94C4C")}
			>
				{text}
			</a>
		</div>
	);
}
