/* Change this file to get your personal Porfolio */

// Website related settings
const settings = {
  isSplash: false, // Change this to false if you don't want Splash screen.
};

//dHome Page
// const greeting = {
//   title: "Hello!",
//   logo_name: "VJ TechServe",
//   subTitle:
//     "VJ Techserve Private Limited has been deemed as a prominently recognized Distributor/Channel Partner of C.C.JENSEN A/S Filter Inserts, Fine Filters and Filter Separators. ",
//   mail: "mailto:",
// };

const socialMediaLinks = {
  /* Your Social Media Link */
  linkedin: "https://www.linkedin.com/",
  gmail: "",
  facebook: " ",
  twitter: "https://www.twitter.com",
  youtube: "https://www.youtube.com",
};

// Contact Page
const contactPageData = {
  contactSection: {
    title: "Contact Us",
    description:
      "You can contact me at the places mentioned below. I will try to get back to you as fast as I can. ",
    address:
      "4, Rydham Apartments, 72/73 Azad Society, Ambawadi, Ahmedabad-15, India",
    phone: "+91 99244 53338",
    email: "ananddineshmehta@gmail.com",
    bhours: "9:00AM - 5:00PM IST",
  },
};

const products = {
  filter_inserts: [
    {
      img:
        "https://5.imimg.com/data5/IO/GH/IR/SELLER-27829095/a-15-25-filter-insert-250x250.jpg",
      name: "CJC A 15/25 Filter Insert",
      url:
        "https://www.indiamart.com/vj-techserve/filter-inserts.html#cjc-a-15-25-filter-insert",
      description:
        "The CJC® A 15/25 Filter Insert is ideal for oil filtration of mineral and synthetic based engine lube oils, hydraulic oils, honing oils, quenching oils",
      id: 1,
    },
    {
      img:
        "https://5.imimg.com/data5/SO/BP/PC/SELLER-27829095/b-15-25-filter-insert-250x250.jpg",
      name: "CJC B 15/25 Filter Insert",
      url:
        "https://www.indiamart.com/proddetail/cjc-b-15-25-filter-insert-21259888733.html",
      description:
        "CJC® B 15/25 Filter Insert is manufactured especially for CJC® Oil Filter for filtration of mineral and synthetic based Hyydraulic oils, turbine lube oils, lube oils, honing oils, rolling oils, gear oils",
      id: 2,
    },
    {
      img:
        "https://5.imimg.com/data5/FG/QV/GF/SELLER-27829095/a-27-27-filter-insert-250x250.jpg",
      name: "CJC A 27/27 Filter Insert",
      url:
        "https://www.indiamart.com/proddetail/cjc-a-27-27-filter-insert-21259825355.html",
      description:
        "The CJC® A 27/27 Filter Insert is ideal for the oil filtration of mineral-based engine lube oils, hydraulic oils, honing oils, quenching oils",
      id: 3,
    },
    {
      img:
        "https://5.imimg.com/data5/LX/HA/CH/SELLER-27829095/blda-27-27-filter-insert-250x250.jpg",
      name: "CJC BLDA 27/27 Filter Insert",
      url:
        "https://www.indiamart.com/proddetail/cjc-blda-27-27-filter-insert-21259932130.html",
      description:
        "CJC® BLDA 27/27 Filter Insert is manufactured especially for CJC® Oil Filters for filtration ofWater glycols",
      id: 4,
    },
    {
      img:
        "https://5.imimg.com/data5/KQ/MN/NC/SELLER-27829095/bnk-27-27-filter-insert-250x250.jpg",
      name: "CJC BNK 27/27 Filter Insert",
      url:
        "https://www.indiamart.com/proddetail/cjc-bnk-27-27-filter-insert-21260019562.html",
      description:
        "The BNK 27/27 Filter Insert is manufactured especially for CJC® Oil Filters for filtration of Insulating oils in tap changers for efficient particle and water removal.",
      id: 5,
    },
    {
      img:
        "https://5.imimg.com/data5/GT/YE/BY/SELLER-27829095/b-27-27-filter-insert-500x500.jpg",
      name: "CJC B 27/27 Filter Insert",
      url:
        "https://www.indiamart.com/proddetail/cjc-b-27-27-filter-insert-21259901462.html",
      description:
        "CJC® B 27/27 Filter Insert is manufactured especially for CJC® Oil Filter for filtration of mineral and synthetic based oils",
      id: 6,
    },
    {
      img:
        "https://5.imimg.com/data5/VV/CH/XL/SELLER-27829095/b-15-12-filter-insert-500x500.jpg",
      name: "CJC B 15/12 Filter Insert",
      url:
        "https://www.indiamart.com/proddetail/cjc-b-15-12-filter-insert-21259880697.html",
      description:
        "CJC® B 15/12 Filter Insert is manufactured especially for CJC® Oil Filter for filtration of mineral and synthetic based oils",
      id: 7,
    },
    {
      img:
        "https://5.imimg.com/data5/IR/OC/KC/SELLER-27829095/filter-insert-bla-27-27-500x500.jpg",
      name: "CJC Filter Insert BLA 27/27",
      url:
        "https://www.indiamart.com/proddetail/cjc-filter-insert-bla-27-27-21259916962.html",
      description:
        "CJC® BLA 27/27 Filter Insert is manufactured especially for CJC® Fine Filters for filtration of water glycols and water-contaminated oils, where water is removed by other means",
      id: 7,
    },
    {
      img:
        "https://5.imimg.com/data5/DP/IP/DH/SELLER-27829095/bm-27-27-filter-insert-500x500.jpg",
      name: "CJC BM 27/27 Filter Insert",
      url:
        "https://www.indiamart.com/proddetail/cjc-bm-27-27-filter-insert-21259995697.html",
      description:
        "CJC® BM 27/27 Filter Insert is manufactured especially for CJC® Oil Filter for filtration in cement mill gear applications, using either Mineral or synthetic gear oils",
      id: 8,
    },
  ],
  fine_filters: [
    {
      img:
        "https://5.imimg.com/data5/GV/SO/NQ/SELLER-27829095/hdu-27-27-fine-filter-500x500.jpg",
      name: "CJC HDU 27/27 Fine Filter",
      url:
        "https://www.indiamart.com/proddetail/cjc-hdu-27-27-fine-filter-21258802397.html",
      description:
        "HDU 27/- is a fine filter for offline oil filtration for medium to large hydraulic power transmissions, lubrication, cooling and quenching systems.",
      id: 1,
    },
    {
      img:
        "https://5.imimg.com/data5/SZ/LF/SR/SELLER-27829095/hdu-15-25-fine-filter-500x500.jpg",
      name: "CJC HDU 15/25 Fine Filter",
      url:
        "https://www.indiamart.com/proddetail/cjc-hdu-15-25-fine-filter-21258768973.html",
      description:
        "HDU 15/25 PV is a fine filter for offline oil filtration in hydraulic power transmission, lubrication, cooling, and small quench-oil systems.",
      id: 2,
    },
    {
      img:
        "https://5.imimg.com/data5/PN/HG/YK/SELLER-27829095/hdu-427-108-fine-filter-500x500.jpg",
      name: "CJC HDU 427/108 Fine Filter",
      url:
        "https://www.indiamart.com/proddetail/cjc-hdu-427-108-fine-filter-21258833691.html",
      description:
        "HDU 427/- are Fine Filters for offline oil filtration with 4 stays/stacks of filter inserts fitted inside a single filter housing. They are used to maintain clean oils and fluids in 2 & 4 stroke diesel engines, hydraulic power transmission, lubrication, cooling and quenching systems.",
      id: 3,
    },
    {
      img:
        "https://5.imimg.com/data5/GC/ND/IG/SELLER-27829095/2x27-108-fine-filter-hdu-multi-stay-500x500.jpg",
      name: "2x27/108 Fine Filter HDU Multi Stay",
      url:
        "https://www.indiamart.com/proddetail/2x27-108-fine-filter-hdu-multi-stay-21258852891.html",
      description:
        "HDU Multistay are Fine Filters for offline oil filtration with 2, 3 or more HDU 27/- series filter housings installed in parallel, used for maintenance of fluids in large hydraulic power transmission, lubrication, cooling and quenching oil systems.",
      id: 4,
    },
    {
      img:
        "https://5.imimg.com/data5/PG/EX/IC/SELLER-27829095/hdu-15-12-fine-filter-500x500.jpg",
      name: "CJC HDU 15/12 Fine Filter",
      url:
        "https://www.indiamart.com/proddetail/cjc-hdu-15-12-fine-filter-21258726448.html",
      description:
        "HDU 15/12 is a compact offline oil filter for maintaining clean oil in (power) transmission, hydraulic and lubrication circuits.",
      id: 4,
    },
  ],
  filter_seperators: [
    {
      img:
        "https://5.imimg.com/data5/ZZ/RG/CT/SELLER-27829095/ptu-15-25-filter-separator-250x250.jpg",
      name: "CJC PTU 15/25 Filter Separator",
      url:
        "https://www.indiamart.com/proddetail/cjc-ptu-15-25-filter-separator-21258968548.html",
      description:
        "PTU 15/25 Hydraulic is an off-line oil filtration system combining continuous water separation and oil filtration",
      id: 1,
    },
    {
      img:
        "https://5.imimg.com/data5/OA/ZK/FR/SELLER-27829095/ptu2-27-27-filter-separator-250x250.jpg",
      name: "CJC PTU 15/25 Filter Separator",
      url:
        "https://www.indiamart.com/proddetail/cjc-ptu2-27-27-filter-separator-21258986933.html",
      description:
        "PTU2 27/27 Diesel is an off-line oil filtration system, combining continuous water separation with oil filtration and is used for diesel oils, removing water, particles, and oil degradation products (oxidation, resin/sludge, varnish).",
      id: 2,
    },
    {
      img:
        "https://5.imimg.com/data5/DF/UN/LG/SELLER-27829095/ptu-27-54-filter-separator-250x250.jpg",
      name: "CJC PTU 27/54 Filter Separator",
      url:
        "https://www.indiamart.com/proddetail/cjc-ptu-27-54-filter-separator-21259013588.html",
      description:
        "PTU2 27/27 Diesel is an off-line oil filtration system, combining continuous water separation with oil filtration and is used for diesel oils, removing water, particles, and oil degradation products (oxidation, resin/sludge, varnish).",
      id: 3,
    },
    {
      img:
        "https://5.imimg.com/data5/NB/BC/JL/SELLER-27829095/ptu-15-12-compact-filter-separator-250x250.jpg",
      name: "CJC PTU 15/12 Compact Filter Separator",
      url:
        "https://www.indiamart.com/proddetail/cjc-ptu-15-12-compact-filter-separator-21258916433.html",
      description:
        "PTU 15/12 is a compact filer separator for maintaining clean oil and dry oil",
      id: 4,
    },
    {
      img:
        "https://5.imimg.com/data5/QZ/NC/NT/SELLER-27829095/ptu3-27-81-filter-separator-250x250.jpg",
      name: "CJC PTU3 27/81 Filter Separator",
      url:
        "https://www.indiamart.com/proddetail/cjc-ptu3-27-81-filter-separator-21259068712.html",
      description:
        "PTU3 27/- are off-line oil filtration systems, combining continuous water separation with oil filtration and is used for hydraulic oils, turbine lubrication oils and gear oils, removing water, particles, and oil degradation products (oxidation, resin/sludge, varnish).",
      id: 4,
    },
    {
      img:
        "https://5.imimg.com/data5/MC/RG/HK/SELLER-27829095/ptu-multi-stay-filter-separator-500x500.jpg",
      name: "CJC PTU Multi Stay Filter Separator",
      url:
        "https://www.indiamart.com/proddetail/cjc-ptu-multi-stay-filter-separator-21259571762.html",
      description:
        "The PTU3 Multistay series includes offline oil filtration systems with 2, 3 or more PTU3 27/- Filter Separators mounted on a common drain tank base.",
      id: 5,
    },
  ],
  varnish_removal_insert: [
    {
      img:
        "https://5.imimg.com/data5/JK/YF/JG/SELLER-27829095/varnish-removal-unit-vru-250x250.jpg",
      name: "Varnish Removal Unit",
      url:
        "https://www.indiamart.com/proddetail/cjc-varnish-removal-unit-vru-21258697962.html",
      description:
        "The CJC® Varnish Removal Unit, VRU is designed for an extraordinary efficient removal of soluble varnish from an oil.",
      id: 1,
    },
  ],
  water_removers: [
    {
      img:
        "https://5.imimg.com/data5/HX/DM/ON/SELLER-27829095/desorber-d10-water-remover-500x500.jpg",
      name: "CJC Desorber D10 Water Remover",
      url:
        "https://www.indiamart.com/proddetail/cjc-desorber-d10-water-remover-21259603933.html",
      description:
        "Water in oil leads to reduced viscosity, reduced filterability, reduced lubricity, formation of rust, bacterial growth and increased oil oxidation - all factors that lead to reduced lifetime of both components and oil.",
      id: 1,
    },
    {
      img:
        "https://5.imimg.com/data5/NL/SV/RT/SELLER-27829095/desorber-d30-water-remover-500x500.jpg",
      name: "CJC Desorber D30 Water Remover",
      url:
        "https://www.indiamart.com/proddetail/cjc-desorber-d30-water-remover-21259632991.html",
      description:
        "Water in oil leads to reduced viscosity, reduced filterability, reduced lubricity, formation of rust, bacterial growth and increased oil oxidation - all factors that lead to reduced lifetime of both components and oil.",
      id: 2,
    },
    {
      img:
        "https://5.imimg.com/data5/EK/RI/WC/SELLER-27829095/desorber-d88-water-remover-500x500.jpg",
      name: "CJC Desorber D88 Water Remover",
      url:
        "https://www.indiamart.com/proddetail/cjc-desorber-d88-water-remover-21259669397.html",
      description:
        "Water in oil leads to reduced viscosity, reduced filterability, reduced lubricity, formation of rust, bacterial growth and increased oil degradation - all factors that lead to reduced lifetime of both components and oil.",
      id: 3,
    },
  ],
  heat_tr_oil_fltr: [
    {
      img:
        "https://5.imimg.com/data5/AK/EK/ZC/SELLER-27829095/hto-hp-27-108-heat-transfer-oil-filter-500x500.jpg",
      name: "CJC HTO HP 27/108 Heat Transfer Oil Filter",
      url:
        "https://www.indiamart.com/proddetail/cjc-hto-hp-27-108-heat-transfer-oil-filter-21258863933.html",
      description:
        "HTO HP 27/108 GP, Heat Transfer Oil Filter / High Pressure Filter, is a fine filter for offline oil filtration with 1, 2, 3 or more HTO 27/108 filter housings installed in parallel, used for maintenance of heat transfer oil in central heating systems and where high processing temperatures are necessary.",
      id: 1,
    },
  ],
  gfu_multistay: [
    {
      img:
        "https://5.imimg.com/data5/FR/NF/ZQ/SELLER-27829095/gear-flushing-unit-gfu-multistay-500x500.jpg",
      name: "CJC Gear Flushing Unit GFU Multistay",
      url:
        "https://www.indiamart.com/proddetail/cjc-gfi-27-27-gear-flushing-insert-21260043688.html",
      description:
        "The CJC® Gear Flushing Unit, GFU is designed for cleaning of flushing oil during the flushing procedure of new or refurbished gear boxes. It maintains the oil contamination level low continuous during the flushing process, reducing this period to a minimum. Furthermore, documenting the cleanliness simultaneously as well.",
      id: 1,
    },
    {
      img:
        "https://5.imimg.com/data5/AV/AD/RC/SELLER-27829095/gfi-27-27-gear-flushing-insert-500x500.jpg",
      name: "CJC GFI 27/27 Gear Flushing Insert",
      url:
        "https://www.indiamart.com/proddetail/cjc-gear-flushing-unit-gfu-multistay-21258607312.html",
      description:
        "The CJC® Gear Flushing insert, GFi 27/27 is specifically designed for filtration and cleaning of gearboxes. The CJC® GFi 27/27 has a high capacity for submicron particles and is documented to remove considerable amounts of particles down to 3 microns or even lower, with improved efficiency over the standard CJC® Filter Inserts.",
      id: 2,
    },
  ],
  blue_baleen: [
    {
      img:
        "https://5.imimg.com/data5/FD/YJ/VO/SELLER-27829095/blue-baleen-oil-absorb-multistay-500x500.jpg",
      name: "CJC Blue Baleen Oil Absorb Multistay",
      url:
        "https://www.indiamart.com/proddetail/cjc-blue-baleen-oil-absorb-multistay-21259732830.html",
      description:
        "CJC® Blue Baleen Oil Absorb 38/100 Multistay is a bilge water polishing system with 8 inserts fitted in two parallel 38/100 housings. On request, the Multistay system is available with more than two housings, custom-made for the application.",
      id: 1,
    },
    {
      img:
        "https://5.imimg.com/data5/RM/JI/MI/SELLER-27829095/blue-baleen-pre-conditioner-250x250.jpg",
      name: "CJC Blue Baleen Pre Conditioner",
      url:
        "https://www.indiamart.com/proddetail/cjc-blue-baleen-pre-conditioner-21259755391.html",
      description:
        "The CJC® Blue Baleen PreConditioner (PC) is a bag filter using CJC® Blue Baleen PreConditioner Inserts (PCI), designed for pre-conditioning of bilge water and other types of process or waste water.",
      id: 2,
    },
    {
      img:
        "https://5.imimg.com/data5/BC/PP/OE/SELLER-27829095/absorb-blue-baleen-oil-flushing-unit-500x500.jpg",
      name: "CJC Absorb Blue Baleen Oil Flushing Unit",
      url:
        "https://www.indiamart.com/proddetail/cjc-absorb-blue-baleen-oil-flushing-unit-21259715962.html",
      description:
        "CJC® Blue Baleen Oil Absorb OA 38/100 is a single stay bilge water polishing system with four inserts fitted inside one housing.",
      id: 3,
    },
  ],
  contamination_monitors: [
    {
      img:
        "https://5.imimg.com/data5/OM/BE/BT/SELLER-27829095/ocm-15-oil-contamination-monitor-500x500.jpg",
      name: "CJC OCM 15 Oil Contamination Monitor",
      url:
        "https://www.indiamart.com/vj-techserve/contamination-monitors.html#cjc-ocm-15-oil-contamination-monitor",
      description:
        "The CJC® Oil Contamination Monitor, OCM15 measures wear generation in oil systems. Used for hydraulic, lube and gear oils. ",
      id: 1,
    },
  ],
  pressure_gauge: [
    {
      img:
        "https://5.imimg.com/data5/MZ/NF/KJ/SELLER-27829095/7-bar-pressure-gauge-500x500.jpg",
      name: "CJC 7 Bar Pressure Gauge",
      url:
        "https://www.indiamart.com/proddetail/cjc-7-bar-pressure-gauge-21260147497.html",
      description:
        "The CJC® Pressure Gauge is used to monitor the pressure drop over the offline oil filtration system. The Pressure Gauge is suitable for use on CJC® Fine Filters and Filter Separators.",
      id: 1,
    },
    {
      img:
        "https://5.imimg.com/data5/IV/CC/CC/SELLER-27829095/7-bar-pressure-gauge-with-mini-mess-500x500.jpg",
      name: "CJC 7 Bar Pressure Gauge With Mini Mess",
      url:
        "https://www.indiamart.com/proddetail/cjc-7-bar-pressure-gauge-with-mini-mess-21260206133.html",
      description:
        "The CJC® Pressure Gauge is used to monitor the pressure drop over the offline oil filtration system. The Pressure Gauge is suitable for use on CJC® Fine Filters and Filter Separators.",
      id: 2,
    },
  ],
  automatic_air_vent: [
    {
      img:
        "https://5.imimg.com/data5/IV/FU/WA/SELLER-27829095/automatic-air-vent-500x500.jpg",
      name: "CJC Automatic Air Vent",
      url:
        "https://www.indiamart.com/vj-techserve/automatic-air-vent.html#cjc-automatic-air-vent",
      description:
        "The CJC® Automatic Air Vent is used to release air from the CJC® Filter Housings and makes it easy to fill or drain oil systems.",
      id: 1,
    },
  ],
  tank_adaptors: [
    {
      img:
        "https://5.imimg.com/data5/OO/DN/MF/SELLER-27829095/tank-adaptor-500x500.jpg",
      name: "CJC Tank Adaptor",
      url:
        "https://www.indiamart.com/vj-techserve/tank-adaptors.html#cjc-tank-adaptor",
      description:
        "The CJC® Tank Adaptor with Air Filter makes it easy to install CJC® Off-line Oil Filtration Systems when there is no drain line from the main system.",
      id: 1,
    },
  ],
  filter_extractor: [
    {
      img:
        "https://5.imimg.com/data5/ZL/UI/YV/SELLER-27829095/filter-insert-extractor-500x500.jpg",
      name: "CJC Filter Insert Extractor",
      url:
        "https://www.indiamart.com/vj-techserve/filter-insert-extractor.html#cjc-filter-insert-extractor",
      description:
        "CJC® Filter Inserts, with their high dirt-holding capacity, remove particles, oxidation by-products and other sources of contamination from oil. The contaminants will collate on the filter insert until it contains the maximum amount and needs to be changed.",
      id: 1,
    },
  ],
};

export { settings, socialMediaLinks, contactPageData, products };
