import React from "react";
import "./SocialMedia.css";
import { socialMediaLinks } from "../../portfolio";

export default function socialMedia() {
  return (
		<div className="social-media-div">
			<a
				href={socialMediaLinks.linkedin}
				className="icon-button linkedin"
				target="_blank"
				rel="noopener noreferrer"
			>
				<i className="fab fa-linkedin-in"></i>
				<span></span>
			</a>
			<a
				href={socialMediaLinks.twitter}
				className="icon-button twitter"
				target="_blank"
				rel="noopener noreferrer"
			>
				<i className="fab fa-twitter"></i>
				<span></span>
			</a>
			<a
				href={socialMediaLinks.youtube}
				className="icon-button youtube"
				target="_blank"
				rel="noopener noreferrer"
			>
				<i className="fab fa-youtube"></i>
				<span></span>
			</a>
		</div>
	);
}
