import React, { Component } from "react";
import "./Header.css";
import { NavLink, Link } from "react-router-dom";
import { settings } from "../../portfolio.js";
import logo from "../../assests/images/vjt.svg"; // Tell webpack this JS file uses this image

const onMouseEnter = (event, color) => {
  const el = event.target;
  el.style.color = color;
};

const onMouseOut = (event) => {
  const el = event.target;
  el.style.color = "#000000";
};

class Header extends Component {
  render() {
    const theme = this.props.theme;
    const link = settings.isSplash ? "/splash" : "home";
    return (
      <div>
        <header className="header">
          <NavLink to={link} tag={Link} className="logo">
            {/* <span style={{ color: theme.text }}> </span>
						<span className="logo-name" style={{ color: theme.text }}>
							{greeting.logo_name}
						</span>
						<span style={{ color: theme.text }}></span> */}
            <img src={logo} className="logo-img" alt="Logo" />
          </NavLink>
          <input className="menu-btn" type="checkbox" id="menu-btn" />
          <label className="menu-icon" htmlFor="menu-btn">
            <span className="navicon"></span>
          </label>
          <ul className="menu" style={{ backgroundColor: theme.body }}>
            <li>
              <NavLink
                to="/home"
                tag={Link}
                activeStyle={{ fontWeight: "bold" }}
                style={{ color: theme.text, borderRadius: 5 }}
                onMouseEnter={(event) => onMouseEnter(event, theme.darkShade)}
                onMouseOut={(event) => onMouseOut(event)}
              >
                Home
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/products"
                tag={Link}
                activeStyle={{ fontWeight: "bold" }}
                style={{ color: theme.text, borderRadius: 5 }}
                onMouseEnter={(event) => onMouseEnter(event, theme.darkShade)}
                onMouseOut={(event) => onMouseOut(event)}
              >
                Products
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/about"
                tag={Link}
                activeStyle={{ fontWeight: "bold" }}
                style={{ color: theme.text, borderRadius: 5 }}
                onMouseEnter={(event) => onMouseEnter(event, theme.darkShade)}
                onMouseOut={(event) => onMouseOut(event)}
              >
                About Us
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/contact"
                tag={Link}
                activeStyle={{ fontWeight: "bold" }}
                style={{ color: theme.text, borderRadius: 5 }}
                onMouseEnter={(event) => onMouseEnter(event, theme.darkShade)}
                onMouseOut={(event) => onMouseOut(event)}
              >
                Contact Us
              </NavLink>
            </li>
          </ul>
        </header>
      </div>
    );
  }
}
export default Header;
