import React from "react";
import "./ProjectCard.css";
import { Fade } from "react-reveal";

export default function ProjectCard({ repo, theme }) {
  // function openRepoinNewTab(url) {
  //   var win = window.open(url, "_blank");
  //   win.focus();
  // }

  return (
    <div>
      <Fade bottom duration={2000} distance="40px">
        <div
          className={repo.class}
          key={repo.id}
          // onClick={() => openRepoinNewTab(repo.url)}
          style={{}}
        >
          <div className="repo-name-div">
            <p className="repo-name" style={{ color: "#FFFFFF" }}>
              {repo.name}
            </p>
          </div>
        </div>
      </Fade>
    </div>
  );
}
