import React, { Component } from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import { Fade } from "react-reveal";
import "./ProductComponent.css";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ProductCard from "../../components/ProductCard/ProductCard";

import { products } from "../../portfolio";

class Products extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    const theme = this.props.theme;
    // const p1 = {
    //   url: "Service 1",
    //   name: "Service 1",
    //   description: "A Service",
    //   bg: theme.cardBg,
    // };
    // const p2 = {
    //   url: "Service 2",
    //   name: "Service 2",
    //   description: "A Service",
    //   bg: theme.cardBg2,
    // };
    // const p3 = {
    //   url: "Service 3",
    //   name: "Service 3",
    //   description: "A Service",
    //   bg: theme.cardBg3,
    // };
    // const p4 = {
    //   url: "Service 4",
    //   name: "Service 4",
    //   description: "A Service",
    //   bg: theme.cardBg,
    // };

    return (
      <div className="product-main">
        <Header theme={theme} />
        <div className="basic-contact">
          <Fade bottom duration={1000} distance="40px">
            <div className="product-heading-div">
              <div className="product-heading-text-div">
                <h1
                  className="product-heading-text"
                  style={{ color: theme.text }}
                >
                  {"Products"}
                </h1>
                {/* <p className="product-header-detail-text subTitle" style={{ color: theme.secondaryText }}>
									{"Distributor / Channel Partner of a wide range of products which include CJC A 15/25 Filter Insert, CJC B 15/25 Filter Insert, CJC A 27/27 Filter Insert, CJC BLDA 27/27 Filter Insert and CJC BNK 27/27 Filter Insert."}
								</p> */}
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <h1 className="product-header-form-detail-text subTitle">
                      Filter Inserts
                    </h1>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="resume-btn-div">
                      <div className="flex-container">
                        {products.filter_inserts.map((fa) => {
                          return (
                            <ProductCard repo={fa} theme={theme} key={fa.id} />
                          );
                        })}
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <h1 className="product-header-form-detail-text subTitle">
                      Fine Filters
                    </h1>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="resume-btn-div">
                      <div className="flex-container">
                        {products.fine_filters.map((fa) => {
                          return (
                            <ProductCard repo={fa} theme={theme} key={fa.id} />
                          );
                        })}
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <h1 className="product-header-form-detail-text subTitle">
                      Filter Seperators
                    </h1>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="resume-btn-div">
                      <div className="flex-container">
                        {products.filter_seperators.map((fa) => {
                          return (
                            <ProductCard repo={fa} theme={theme} key={fa.id} />
                          );
                        })}
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <h1 className="product-header-form-detail-text subTitle">
                      Varnish Removal Insert
                    </h1>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="resume-btn-div">
                      <div className="flex-container">
                        {products.varnish_removal_insert.map((fa) => {
                          return (
                            <ProductCard repo={fa} theme={theme} key={fa.id} />
                          );
                        })}
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <h1 className="product-header-form-detail-text subTitle">
                      Water Removers
                    </h1>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="resume-btn-div">
                      <div className="flex-container">
                        {products.water_removers.map((fa) => {
                          return (
                            <ProductCard repo={fa} theme={theme} key={fa.id} />
                          );
                        })}
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <h1 className="product-header-form-detail-text subTitle">
                      Heat Transfer Oil Filter
                    </h1>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="resume-btn-div">
                      <div className="flex-container">
                        {products.heat_tr_oil_fltr.map((fa) => {
                          return (
                            <ProductCard repo={fa} theme={theme} key={fa.id} />
                          );
                        })}
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <h1 className="product-header-form-detail-text subTitle">
                      GFU Multistay
                    </h1>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="resume-btn-div">
                      <div className="flex-container">
                        {products.gfu_multistay.map((fa) => {
                          return (
                            <ProductCard repo={fa} theme={theme} key={fa.id} />
                          );
                        })}
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <h1 className="product-header-form-detail-text subTitle">
                      Blue Baleen Oil Flushing Unit
                    </h1>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="resume-btn-div">
                      <div className="flex-container">
                        {products.blue_baleen.map((fa) => {
                          return (
                            <ProductCard repo={fa} theme={theme} key={fa.id} />
                          );
                        })}
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <h1 className="product-header-form-detail-text subTitle">
                      Contamination Monitors
                    </h1>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="resume-btn-div">
                      <div className="flex-container">
                        {products.contamination_monitors.map((fa) => {
                          return (
                            <ProductCard repo={fa} theme={theme} key={fa.id} />
                          );
                        })}
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <h1 className="product-header-form-detail-text subTitle">
                      Pressure Gauge
                    </h1>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="resume-btn-div">
                      <div className="flex-container">
                        {products.pressure_gauge.map((fa) => {
                          return (
                            <ProductCard repo={fa} theme={theme} key={fa.id} />
                          );
                        })}
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <h1 className="product-header-form-detail-text subTitle">
                      Automatic Air Vent
                    </h1>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="resume-btn-div">
                      <div className="flex-container">
                        {products.automatic_air_vent.map((fa) => {
                          return (
                            <ProductCard repo={fa} theme={theme} key={fa.id} />
                          );
                        })}
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <h1 className="product-header-form-detail-text subTitle">
                      Tank Adaptors
                    </h1>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="resume-btn-div">
                      <div className="flex-container">
                        {products.tank_adaptors.map((fa) => {
                          return (
                            <ProductCard repo={fa} theme={theme} key={fa.id} />
                          );
                        })}
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <h1 className="product-header-form-detail-text subTitle">
                      Filter Insert Extractor
                    </h1>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="resume-btn-div">
                      <div className="flex-container">
                        {products.filter_extractor.map((fa) => {
                          return (
                            <ProductCard repo={fa} theme={theme} key={fa.id} />
                          );
                        })}
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>
              </div>
            </div>
          </Fade>
        </div>
        <Footer theme={this.props.theme} onToggle={this.props.onToggle} />
      </div>
    );
  }
}

export default Products;
