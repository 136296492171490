import React from "react";
import "./App.css";
import Main from "./containers/Main";
import { ThemeProvider } from "styled-components";
import { chosenTheme } from "./theme";
import { GlobalStyles } from "./global";
import MetaTags from "react-meta-tags";

function App() {
  return (
    <ThemeProvider theme={chosenTheme}>
      <MetaTags>
        <meta
          name="google-site-verification"
          content="wUdSS_zMEPZoS_e_BNn5sj3Y5B-4ZsVXquC8f7yaRPs"
        />
      </MetaTags>
      <>
        <GlobalStyles />
        <div>
          <Main theme={chosenTheme} />
        </div>
      </>
    </ThemeProvider>
  );
}

export default App;
